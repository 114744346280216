
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import formatDate from 'xe-utils/toDateString'
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'productNumberPurchaseRecord',
      title: '品號歷史進貨紀錄表',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: true,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'PurchaseDate', title: '進貨日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: 'ManufacturerShortName', title: '廠商簡稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ManufacturerCode', title: '廠商代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ProductNo', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseName', title: '退稅用品名', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseSpecifications', title: '退稅用規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseNumber', title: '進貨單號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseQuantity', title: '進貨量', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Uint', title: '單位', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Currency', title: '幣別', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ExchangeRate', title: '匯率', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Price', title: '單價', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatAmount(cellValue) },
        { field: 'Amount', title: '總價', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatAmount(cellValue) },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('productNumberPurchaseRecord/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('productNumberPurchaseRecord/query') : undefined,
        save: model
          ? (params) => model.dispatch('productNumberPurchaseRecord/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'PurchaseDate',
          title: '進貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'ManufacturerShortName',
          title: '廠商簡稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ManufacturerCode',
          title: '廠商代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseName',
          title: '退稅用品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseSpecifications',
          title: '退稅用規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseNumber',
          title: '進貨單號',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseQuantity',
          title: '進貨量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: false },
          }
        },
        {
          field: 'AvailableQuantity',
          title: '可用量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: false },
          }
        },
        {
          field: 'Uint',
          title: '單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Currency',
          title: '幣別',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ExchangeRate',
          title: '匯率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'Price',
          title: '單價',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'Amount',
          title: '總價',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'ImportTaxRate',
          title: '進口稅率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'BurdenRate',
          title: '應加費用率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'DutyAmount',
          title: '完稅金額',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'MaterialTax',
          title: '原料單位稅款',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'CustomsDeclarationDate',
          title: '報關日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'CustomsDeclarationNumber',
          title: '報單號碼',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      formatAmount,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
