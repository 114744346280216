<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">品號歷史進貨紀錄表</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <!-- <vxe-button type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false }"></vxe-button> -->
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import formatDate from 'xe-utils/toDateString'
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'productNumberPurchaseRecord',
      title: '品號歷史進貨紀錄表',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: true,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'PurchaseDate', title: '進貨日期', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: 'ManufacturerShortName', title: '廠商簡稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ManufacturerCode', title: '廠商代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ProductNo', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseName', title: '退稅用品名', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'TaxRefundUseSpecifications', title: '退稅用規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseNumber', title: '進貨單號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'PurchaseQuantity', title: '進貨量', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Uint', title: '單位', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Currency', title: '幣別', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ExchangeRate', title: '匯率', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Price', title: '單價', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatAmount(cellValue) },
        { field: 'Amount', title: '總價', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatAmount(cellValue) },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('productNumberPurchaseRecord/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('productNumberPurchaseRecord/query') : undefined,
        save: model
          ? (params) => model.dispatch('productNumberPurchaseRecord/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'PurchaseDate',
          title: '進貨日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'ManufacturerShortName',
          title: '廠商簡稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ManufacturerCode',
          title: '廠商代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseName',
          title: '退稅用品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseSpecifications',
          title: '退稅用規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseNumber',
          title: '進貨單號',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PurchaseQuantity',
          title: '進貨量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: false },
          }
        },
        {
          field: 'AvailableQuantity',
          title: '可用量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: false },
          }
        },
        {
          field: 'Uint',
          title: '單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Currency',
          title: '幣別',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ExchangeRate',
          title: '匯率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'Price',
          title: '單價',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'Amount',
          title: '總價',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'ImportTaxRate',
          title: '進口稅率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'BurdenRate',
          title: '應加費用率',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'DutyAmount',
          title: '完稅金額',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'MaterialTax',
          title: '原料單位稅款',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'float', readonly: true },
          }
        },
        {
          field: 'CustomsDeclarationDate',
          title: '報關日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'CustomsDeclarationNumber',
          title: '報單號碼',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      formatAmount,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
